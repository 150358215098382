import React, { useState, useRef, useEffect } from 'react';
import isValidYouTubeURL from 'utils/isValidYouTubeURL';
import getVideoId from 'get-video-id';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import { OpenNewLinkIcon, CloseXIcon } from 'Icons';
import { isMobile } from 'react-device-detect';
import useUser from 'contexts/userContext';
import AuthDS from 'data/AuthDS';

const YouTubeURLInputField = () => {
  const { userId } = useUser();
  const [value, setValue] = useState('');
  const isInLandingPage = window.location.pathname === '/';

  const __handleSubmit = useUpdatingCallback((videoId) => {
    if (!userId) {
      AuthDS.get().setRedirectVideoId(videoId);
      AuthDS.get().openAuthModal();
      return;
    }

    setValue('');
    if (isInLandingPage) {
      window.open(`${window.location.origin}/${videoId}`, '_self');
    } else {
      window.open(`${window.location.origin}/${videoId}`, '_blank');
    }
  });

  const onSubmit = useUpdatingCallback((evt) => {
    evt.preventDefault();
    if (!isValidYouTubeURL(value)) {
      return;
    }

    const { id: videoId } = getVideoId(value);

    __handleSubmit(videoId);
  });

  useEffect(() => {
    const cachedVideoId = window.sessionStorage.getItem('videoId');

    if (cachedVideoId) {
      __handleSubmit(cachedVideoId);
      window.sessionStorage.removeItem('videoId');
    }
  }, [userId]);

  const onChange = useUpdatingCallback((evt) => {
    setValue(evt.target.value);
  });

  const onReset = useUpdatingCallback(() => {
    setValue('');
  });

  const onKeyDown = useUpdatingCallback((evt) => {
    if (evt.key === 'Enter') {
      onSubmit(evt);
    }
  });

  const inputRef: any = useRef();
  useEffect(() => {
    if (window.location.pathname === '/' && !isMobile) {
      inputRef?.current?.focus();
    }
  }, []);

  return (
    <>
      <div
        css={
          isMobile
            ? {
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'rgba(0,0,0,0.2)',
                borderRadius: 6,
                width: '100%',
                height: 44,
                paddingLeft: 4,
                paddingRight: 4,
                marginLeft: 5,
                marginRight: 5,
              }
            : {
                display: 'flex',
                flexDirection: 'row',
                paddingLeft: 4,
                paddingRight: 12,
                maxHeight: 44,
                height: 44,
                backgroundColor: 'rgba(0,0,0,0.2)',
                backdropFilter: 'blur(10px)',
                overflow: 'hidden',
                borderRadius: 6,
                width: isMobile ? '100%' : 400,
                minWidth: 400,
                maxWidth: 400,
                alignItems: 'center',
                position: isInLandingPage ? 'fixed' : 'relative',
                left: isInLandingPage ? '50%' : undefined,
                transform: isInLandingPage ? 'translate(-50%)' : undefined,
              }
        }
      >
        <input
          ref={inputRef}
          type='text'
          autoComplete='off'
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder='Enter a YouTube link'
          css={{
            overflow: 'scroll',
            width: '100%',
            fontFamily: 'inherit',
            background: 'transparent',
            fontSize: 16,
            fontWeight: 400,
            color: isInLandingPage ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8)',
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            whiteSpace: 'nowrap',
            WebkitBoxShadow: 'none',
            MozBoxShadow: 'none',
            paddingRight: 0,
            paddingLeft: 6,
            '&::placeholder': {
              color: isInLandingPage ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.5)',
            },
            '&:-ms-input-placeholder': {
              color: isInLandingPage ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.5)',
            },
            '&::-ms-input-placeholder': {
              color: isInLandingPage ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.5)',
            },
          }}
        />
        {value && (
          <div css={{ display: 'flex', flexDirection: 'row' }}>
            <div
              css={{ height: 44, width: 1, background: 'rgba(255,255,255,0.3)', marginRight: 4 }}
            />
            <div
              onClick={onReset}
              css={{
                cursor: 'pointer',
                '& svg path': {
                  stroke: isInLandingPage ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.5)',
                },
                '&:hover svg path': {
                  stroke: isInLandingPage ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8)',
                },
                display: 'flex',
                alignItems: 'center',
                marginRight: 6,
                marginLeft: 6,
                padding: 4,
              }}
            >
              <CloseXIcon />
            </div>
          </div>
        )}
        <div
          onClick={onSubmit}
          css={{
            cursor: 'pointer',
            '& svg path': { stroke: isInLandingPage ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.5)' },
            '&:hover svg path': {
              stroke: isInLandingPage ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.8)',
            },
            display: 'flex',
            alignItems: 'center',
            padding: 4,
          }}
        >
          <OpenNewLinkIcon />
        </div>
      </div>
    </>
  );
};

export default YouTubeURLInputField;
