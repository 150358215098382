import { useState, useEffect, useRef } from 'react';
import ChatFeedForm from 'components/ChatFeedForm';
import CallAPI from 'utils/CallAPI';
import CircularProgress from '@mui/material/CircularProgress';
import ChatFeedMessage from 'components/ChatFeedMessage';
import { useTranscript } from 'contexts/transcriptContext';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';
import Player from 'types/Player';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import BasicButton from 'components/BasicButton';
import Dimensions from 'utils/Dimensions';
import * as Sentry from '@sentry/react';
import useStopWatch from 'components/useStopWatch';
import { zeroPad } from 'react-countdown';
import colors from 'colors';
import ChatMessagesDS, { useChatMessages } from 'data/ChatMessagesDS';

const ChatFeed = ({
  playerRef,
  setIsPlaying,
  shouldHideChat = false,
}: {
  playerRef: Player;
  setIsPlaying: Function;
  shouldHideChat?: boolean;
}) => {
  const timerRef = useRef<any>(null);

  useEffect(() => {
    const sessionMessages_str = sessionStorage.getItem('messages');
    sessionStorage.removeItem('messages');
    if (sessionMessages_str) {
      const sessionMessages = JSON.parse(sessionMessages_str);
      ChatMessagesDS.get().addMessages(sessionMessages);
    }
  }, []);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const { transcript, isLoadingTranscript, isError } = useTranscript();
  const [canShowCountDown, setCanShowCountDown] = useState(false);
  const [sentReadyMessage, setSentReadyMessage] = useState(false);
  const [sentLoadingMessage, setSentLoadingMessage] = useState(false);
  const { seconds: timerSeconds, start: startTimer, pause: pauseTimer } = useStopWatch();

  useEffect(() => {
    if (sentReadyMessage) {
      return;
    }

    if (sentLoadingMessage) {
      if (!isLoadingTranscript) {
        pauseTimer();
        setSentReadyMessage(true);
        ChatMessagesDS.get().addMessage({
          user: 'CHATGPT',
          id: uuidv4(),
          text: `Your video is ready!`,
        });
      }
      return;
    }
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      if (!isLoadingTranscript) {
        return;
      }
      setCanShowCountDown(true);
      setSentLoadingMessage(true);
      startTimer();

      ChatMessagesDS.get().addMessage({
        user: 'CHATGPT',
        id: uuidv4(),
        text: `
          Hang tight! we are setting up your video, this can take 1-2 minutes. Meanwhile, you can start watching your video. We will notify you when we are done with the setup.`,
      });
    }, 3000);
  }, [isLoadingTranscript, sentReadyMessage, sentLoadingMessage]);

  __useHandleTranscriptionError();
  // temp disable until it fixed for long answers.
  // we should focus on the start rather than the end of the new message
  __useScrollNewMessageIntoView(messagesEndRef);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(!transcript || isLoadingTranscript);
  }, [!!transcript, isLoadingTranscript]);

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const numOFMessages = useChatMessages({ transform: (x) => x?.messages?.length || 0 });

  useEffect(() => {
    // don't pop if no messages are sent
    if (numOFMessages === 0) {
      return;
    }
    // only pop this modal once for each local storage
    // or for people who have not set an email
    const didAskToSubmitEmail = localStorage.getItem('didAskToSubmitEmail');
    const email = localStorage.getItem('email');
    if (didAskToSubmitEmail !== 'TRUE' && !email) {
      localStorage.setItem('didAskToSubmitEmail', 'TRUE');
      setIsEmailModalOpen(true);
    }
  }, [numOFMessages]);

  const { videoId } = useTranscript();

  const onPostMessage = useUpdatingCallback(async ({ text }) => {
    ChatMessagesDS.get().addMessage({ user: 'USER', text, id: uuidv4() });
    await __genAnswer({
      videoId,
      question: text,
      setIsLoading,
    });
  });

  const onGenQuiz = useUpdatingCallback(async () => {
    await __genQuiz({
      videoId,
      setIsLoading,
    });
  });

  const onGenSummary = useUpdatingCallback(async () => {
    await __genSummary({
      videoId,
      setIsLoading,
    });
  });

  if (shouldHideChat) {
    return null;
  }

  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        width: '100%',
        background: '#2d2d2d',
        borderRadius: isMobile ? undefined : 12,
        margin: isMobile ? undefined : 24,
        overflow: 'hidden',
        minWidth: isMobile ? undefined : Dimensions.MIN_CHAT_WIDTH,
        maxWidth: Dimensions.MAX_CHAT_WIDTH,
        paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <div css={{ overflow: 'scroll' }}>
          <__Disclaimer />
          <__Messages
            playerRef={playerRef}
            messagesEndRef={messagesEndRef}
            videoId={videoId}
            setIsPlaying={setIsPlaying}
          />
        </div>
        {isLoadingTranscript && canShowCountDown && !isError && (
          <__Countdown stopWatchSeconds={timerSeconds} countFromSeconds={180} />
        )}
        {!isLoadingTranscript && isLoading && !isError && (
          <div css={{ position: 'relative', width: '100%', height: 80, padding: 20 }}>
            <div
              css={{
                position: 'absolute',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                top: '50%',
              }}
            >
              <CircularProgress />
            </div>
          </div>
        )}
        <ChatFeedForm
          isWaitingForResponse={isLoading}
          onPostMessage={onPostMessage}
          leftActionButtons={[
            <BasicButton key='quiz' text='Quiz me' onClick={onGenQuiz} disabled={isLoading} />,
            <BasicButton
              key='summary'
              text='Summarize'
              onClick={onGenSummary}
              disabled={isLoading}
              extraCSS={{ marginLeft: 6 }}
            />,
          ]}
        />
      </div>
    </div>
  );
};

const __Disclaimer = () => {
  const __BulletPoint = ({ children }) => {
    return (
      <div css={{ display: 'flex', flexDirection: 'row', marginBottom: 6 }}>
        <div css={{ marginRight: 12, marginLeft: 12 }}>&middot;</div>
        {children}
      </div>
    );
  };
  return (
    <div
      css={{
        display: 'flex',
        flexFlow: 'column nowrap',
        width: '100%',
        paddingLeft: isMobile ? 14 : 24,
        paddingRight: isMobile ? 14 : 24,
        fontSize: isMobile ? 14 : 16,
        marginBottom: 12,
        marginTop: 10,
        color: colors.t_text,
      }}
    >
      <div css={{ marginBottom: 8 }}>Disclaimer:</div>
      <div css={{ marginLeft: 12 }}>
        <__BulletPoint>
          Can&apos;t maintain a back-and-forth conversation. A prompt like &quot;what did you mean
          by X?&quot; won&apos;t work. Ask singular clear questions about the video.
        </__BulletPoint>
        <__BulletPoint>Only supports videos in English.</__BulletPoint>
        <__BulletPoint>
          Doesn&apos;t have access to knowledge outside of the video&apos;s transcript. Can only
          respond to questions where the answer is mentioned in the video.
        </__BulletPoint>
        <__BulletPoint>May generate inaccurate information.</__BulletPoint>
        <__BulletPoint>
          Do not share private information. Our engineers may use your chat history to improve our
          answers.
        </__BulletPoint>
      </div>
    </div>
  );
};

const __Messages = ({
  playerRef,
  messagesEndRef,
  videoId,
  setIsPlaying,
}: {
  playerRef: Player;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  videoId: string;
  setIsPlaying: Function;
}) => {
  const chatFeedMessages = useChatMessages({ transform: (x) => x?.messages ?? [] });
  return chatFeedMessages.map((message, i) => {
    const messageElement = (
      <ChatFeedMessage
        key={message.id}
        messageID={message.id}
        author={message.user}
        type={message.type ?? undefined}
        messageText={
          message.text ||
          "Sorry, we're getting a lot of requests right now! Wait a few seconds, and try again!"
        }
        playerRef={playerRef}
        setIsPlaying={setIsPlaying}
        videoId={videoId}
        previousMessageText={
          message.type === 'answer' && i > 0 ? chatFeedMessages[i - 1]?.text : ''
        }
        quizType={message.quizType}
      />
    );

    if (i === chatFeedMessages.length - 1) {
      return (
        <div key={`${message.id}-last`} css={{ position: 'relative' }}>
          <div css={{ position: 'absolute', top: -10 }} ref={messagesEndRef} />
          {messageElement}
        </div>
      );
    }

    return messageElement;
  });
};

const __Countdown = ({
  stopWatchSeconds,
  countFromSeconds,
}: {
  stopWatchSeconds: number;
  countFromSeconds: number;
}) => {
  const invertedTime = Math.floor(countFromSeconds - stopWatchSeconds);
  const minutes = Math.floor(invertedTime / 60);
  const seconds = invertedTime - minutes * 60;

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: isMobile ? 50 : 24 + 37,
        paddingRight: 24,
        marginTop: 10,
        marginBottom: 6,
        fontFamily: 'inherit',
        color: 'white',
      }}
    >
      {stopWatchSeconds >= countFromSeconds || invertedTime < 0 ? (
        <>It&apos;s taking a bit longer than expected, sorry!</>
      ) : (
        <>
          {minutes}:{zeroPad(seconds)}
        </>
      )}
    </div>
  );
};

const __useScrollNewMessageIntoView = (
  messagesEndRef: React.RefObject<HTMLDivElement | null>,
): void => {
  const messageIDsStr = useChatMessages({ transform: (x) => x.messages.map((x) => x.id).join('') });
  useEffect(() => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [messageIDsStr, !!messagesEndRef]);
};

const __useHandleTranscriptionError = (): void => {
  const { isError: error } = useTranscript();

  useEffect(() => {
    if (error) {
      ChatMessagesDS.get().addMessage({
        user: 'CHATGPT',
        text: 'Sorry, an error occurred. Try uploading the video again. Videos must be shorter than 4 hours.',
        id: uuidv4(),
      });
      Sentry.captureException(error);
    }
  }, [!!error]);
};

async function __genAnswer({
  videoId,
  question,
  setIsLoading,
}: {
  videoId: string;
  question: string;
  setIsLoading: Function;
}): Promise<void> {
  setIsLoading(true);
  const response = await CallAPI.getAnswer({ videoId, question });
  if (response) {
    if (response.data.result.success) {
      const { answer } = response.data.result;
      ChatMessagesDS.get().addMessage({
        user: 'CHATGPT',
        type: 'answer',
        text: answer,
        id: uuidv4(),
      });
    } else {
      ChatMessagesDS.get().addMessage({
        user: 'CHATGPT',
        type: 'answer',
        text: response.data.result.message,
        id: uuidv4(),
      });
    }
    setIsLoading(false);
  }
}

async function __genQuiz({
  videoId,
  setIsLoading,
}: {
  videoId: string;
  setIsLoading: Function;
}): Promise<void> {
  ChatMessagesDS.get().addMessage({
    user: 'CHATGPT',
    text: 'I am coming up with a quiz...',
    id: uuidv4(),
  });
  setIsLoading(true);
  const quizHistory = ChatMessagesDS.get()
    .getMessages()
    .filter((m) => m.type === 'quiz')
    .map((m) => ({ type: m.quizType, text: m.text }));

  const response = await CallAPI.getQuiz({ videoId, quizHistory });
  if (response) {
    const { quiz, type: quizType } = response.data.result;
    ChatMessagesDS.get().addMessage({
      user: 'CHATGPT',
      type: 'quiz',
      text: quiz,
      id: uuidv4(),
      quizType,
    });
  }
  setIsLoading(false);
}

async function __genSummary({
  videoId,
  setIsLoading,
}: {
  videoId: string;
  setIsLoading: Function;
}): Promise<void> {
  ChatMessagesDS.get().addMessage({
    user: 'CHATGPT',
    text: 'I am coming up with a summary...',
    id: uuidv4(),
  });
  setIsLoading(true);
  const response = await CallAPI.getSummary({ videoId });
  if (response) {
    const { summary } = response.data.result;
    ChatMessagesDS.get().addMessage({
      user: 'CHATGPT',
      type: 'summary',
      text: summary,
      id: uuidv4(),
    });
  }
  setIsLoading(false);
}

export default ChatFeed;
