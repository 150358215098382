import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import colors from '../colors';
import Dimensions from 'utils/Dimensions';
import { isMobile } from 'react-device-detect';
import YouTubeURLInputField from 'components/YouTubeURLInputField';
import backgroundImageURI from 'utils/backgroundImageURI';
import maxHeightOnMobileSafariCSS from 'utils/maxHeightOnMobileSafariCSS';
import { useTranscript } from 'contexts/transcriptContext';
import LoginOrLogoutButton from 'components/LoginOrLogoutButton';
import isValidYouTubeURL from 'utils/isValidYouTubeURL';
import SignUpModal from 'components/SignUpModal';
import ChatMessagesDS from 'data/ChatMessagesDS';

const BasicPageWrapper = ({ children }) => {
  const [didCheckLocation, setDidCheckLocation] = useState(false);
  const navigate = useNavigate();
  const isInLandingPage = window.location.pathname === '/';

  useEffect(() => {
    const videoId = window.sessionStorage.getItem('videoId');
    if (videoId) {
      let url = 'https://youtu.be/' + videoId;
      if (isValidYouTubeURL(url)) {
        navigate(`/${videoId}`);
      }
    }
    setDidCheckLocation(true);
  }, [window.location]);

  if (!didCheckLocation) return null;

  const extraCSS = isInLandingPage
    ? {
        backgroundImage: `url(${backgroundImageURI})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: isMobile ? '102vw 153vh' : '102vw 153vw',
        backgroundPosition: 'top',
      }
    : {};
  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100vw',
        minHeight: '100vh',
        ...maxHeightOnMobileSafariCSS,
        ...extraCSS,
      }}
    >
      <__Header />
      <SignUpModal />
      {children}
    </div>
  );
};

const __Header = () => {
  const navigate = useNavigate();
  const { setVideoURL, setIsLoadingVideo } = useTranscript();
  const handleLogoClick = useUpdatingCallback(() => {
    setVideoURL('');
    ChatMessagesDS.get().clear();
    setIsLoadingVideo(true);
    navigate('/');
  });

  const isInLandingPage = window.location.pathname === '/';

  if (isMobile && !isInLandingPage) {
    return null;
  }

  return (
    <div
      css={{
        display: 'flex',
        flex: 1,
        minHeight: Dimensions.HEADER_HEIGHT,
        maxHeight: Dimensions.HEADER_HEIGHT,
        paddingLeft: isMobile ? 5 : 24,
        paddingRight: isMobile ? 5 : 24,
        height: Dimensions.HEADER_HEIGHT,
        width: '100vw',
        alignItems: 'center',
        borderBottom: isInLandingPage ? '0' : '1px solid rgba(45, 45, 45, 0.1)',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          maxWidth: isMobile ? '100vw' : Dimensions.MAX_BODY_WIDTH,
          minWidth: isMobile ? '' : Dimensions.MIN_BODY_WIDTH,
          marginLeft: 0,
        }}
      >
        <div onClick={handleLogoClick} css={{ position: 'relative', cursor: 'pointer' }}>
          <img
            alt='Kiwi ai logo'
            css={{ height: 32 }}
            src='https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/apple/237/kiwifruit_1f95d.png'
          />
          <div
            css={{
              position: 'absolute',
              fontSize: 11,
              color: colors.p_brand,
              top: -2,
              left: 35,
            }}
          >
            alpha
          </div>
        </div>
        <div css={{ display: 'flex', flexDirection: 'row' }}>
          {isMobile ? null : <YouTubeURLInputField />}
          <LoginOrLogoutButton isInLandingPage={isInLandingPage} />
        </div>
      </div>
    </div>
  );
};

export default BasicPageWrapper;
