import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import CssBaseline from '@mui/material/CssBaseline';
import LandingPage from 'pages/LandingPage';
import VideoPage from 'pages/VideoPage';
import BasicPageWrapper from 'components/BasicPageWrapper';
import { TranscriptProvider } from 'contexts/transcriptContext';
import { UserProvider } from 'contexts/userContext';
import colors from 'colors';

// adds support for the css-in-js css prop
import type {} from 'styled-components/cssprop';

const customTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  // @ts-ignore
  shadows: shadows.map(() => 'none'),
  palette: {
    mode: 'light',
    primary: {
      main: colors.p_text,
    },
    secondary: {
      main: '#FF621B',
    },
    info: {
      main: '#252525',
    },
    background: {
      default: colors.p_background,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    button: {
      fontFamily: 'Inter, sans-serif',
      textTransform: 'none',
    },
  },
});

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <UserProvider>
          <TranscriptProvider>
            <Routes>
              <Route path='/privacy' element={<_Privacy />} />
              <Route
                path='/:videoId'
                element={
                  <BasicPageWrapper>
                    <VideoPage />
                  </BasicPageWrapper>
                }
              />
              <Route
                path='/'
                element={
                  <BasicPageWrapper>
                    <LandingPage />
                  </BasicPageWrapper>
                }
              />
            </Routes>
          </TranscriptProvider>
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
};

const _Privacy = () => {
  window.location.replace(
    'https://bristle-approval-ebb.notion.site/kiwi-Privacy-Policy-7f8af807c7a5419eada9c65da854170e',
  );
  return null;
};

export default App;
