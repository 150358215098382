import { createContext, useContext, useState, useMemo, useEffect } from 'react';

import CallAPI from 'utils/CallAPI';
import useUser from 'contexts/userContext';
import Transcript, { TranscriptSegment } from 'types/Transcript';
import * as Sentry from '@sentry/react';
import usePollingEffect from 'utils/usePollingEffect';
import getVideoId from 'get-video-id';
import _ from 'lodash';
import demoVideos from 'utils/demoVideos';

const STATUS_COMPLETE = 'COMPLETE';
const CONNECTION_TIMEOUT = 'ECONNABORTED';

type TranscriptContextType = {
  videoURL: string;
  transcript: {
    segments: TranscriptSegment[];
  };
  videoId: string;
  setVideoURL: (val: string) => void;
  setIsLoadingVideo: (val: boolean) => void;
  isError: boolean;
  isLoadingTranscript: boolean;
  isLoadingVideo: boolean;
};

// @ts-ignore
export const TranscriptContext = createContext<TranscriptContextType>({});

export const TranscriptProvider = ({ children }: { children: React.ReactNode }) => {
  const [videoURL, setVideoURL] = useState('');
  const [transcript, setTranscript]: [Transcript, Function] = useState({ segments: [] });
  const [videoId, setVideoId] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
  const [didVideoTimeout, setDidVideoTimeout] = useState(false);
  const { userId, anonVisitorId } = useUser();

  const demoVideoIds = demoVideos.map((x) => x.videoId);

  useEffect(() => {
    const { id } = getVideoId(videoURL);
    const isDemoVideo = demoVideoIds.includes(id || '');
    const isUserLoaded = !!userId || !!anonVisitorId;
    const canFetch = (isDemoVideo && isUserLoaded) || (!isDemoVideo && userId);
    if (videoURL && canFetch) {
      __genPostVideo({
        videoURL,
        setTranscript,
        setIsError,
        setDidVideoTimeout,
        setIsLoadingTranscript,
        setVideoId,
      });
    }
  }, [videoURL, userId, anonVisitorId]);

  const { startPoll, killPoll } = usePollingEffect(
    async () =>
      await __genGetTranscript({
        videoURL,
        setTranscript,
        setIsError,
        setIsLoadingTranscript,
      }),
    [],
    {
      maxPolls: 36, // ~3 min for a 5s interval
      interval: 5_000,
    },
  );

  useEffect(() => {
    if (didVideoTimeout && isLoadingTranscript) {
      startPoll();
    }
  }, [didVideoTimeout]);

  useEffect(() => {
    if (transcript && !_.isEmpty(transcript.segments)) {
      killPoll();
    }
  }, [transcript]);

  const memoedValues = useMemo<TranscriptContextType>(
    () => ({
      videoURL,
      setVideoURL,
      transcript,
      videoId,
      isError,
      isLoadingTranscript,
      isLoadingVideo,
      setIsLoadingVideo,
    }),
    [
      videoURL,
      setVideoURL,
      transcript,
      videoId,
      isError,
      isLoadingTranscript,
      isLoadingVideo,
      setIsLoadingVideo,
    ],
  );

  return <TranscriptContext.Provider value={memoedValues}>{children}</TranscriptContext.Provider>;
};

export const useTranscript = (): TranscriptContextType => {
  return useContext(TranscriptContext);
};

async function __genPostVideo({
  videoURL,
  setTranscript,
  setDidVideoTimeout,
  setIsError,
  setIsLoadingTranscript,
  setVideoId,
}: {
  videoURL: string;
  setTranscript: Function;
  setDidVideoTimeout: (val: boolean) => void;
  setIsError: (val: boolean) => void;
  setIsLoadingTranscript: (val: boolean) => void;
  setVideoId: (val: string) => void;
}): Promise<void> {
  try {
    setIsLoadingTranscript(true);
    const response = await CallAPI.postVideo({ url: videoURL });

    if (response) {
      const { transcript, videoId } = response.data.result;
      setVideoId(videoId);
      setTranscript(transcript);
      setIsLoadingTranscript(false);

      if (response.data.error) {
        Sentry.captureException(response.data.error);
        setIsError(response.data.error);
      }
    }
  } catch (err) {
    if (err.code === CONNECTION_TIMEOUT) {
      setDidVideoTimeout(true);
    } else {
      setIsError(true);
    }
    Sentry.captureException(err);
  }
}

async function __genGetTranscript({
  videoURL,
  setTranscript,
  setIsError,
  setIsLoadingTranscript,
}: {
  videoURL: string;
  setTranscript: Function;
  setIsError: (val: boolean) => void;
  setIsLoadingTranscript: (val: boolean) => void;
}): Promise<void> {
  try {
    const { id: videoId } = getVideoId(videoURL);
    const response = await CallAPI.getTranscript({ videoId: videoId || '' });
    if (response && response.data.result.status === STATUS_COMPLETE) {
      const { transcript } = response.data.result;
      setTranscript(transcript);
      setIsLoadingTranscript(false);

      if (response.data.error) {
        Sentry.captureException(response.data.error);
        setIsError(response.data.error);
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    setIsError(true);
  }
}
