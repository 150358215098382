import React, { useState, useRef } from 'react';
import { GPTSvg } from 'SVGs';
import TextWithClickableTimestamp from 'components/TextWithClickableTimestamp';
import Player from 'types/Player';
import useUpdatingCallback from 'utils/useUpdatingCallback';
import FeedbackButtons from 'components/FeedbackButtons';
import BasicButton from 'components/BasicButton';
import { QuizType } from 'types/Message';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';

const ChatQuiz = ({
  text,
  type,
  playerRef,
  setIsPlaying,
  videoId,
}: {
  type?: QuizType;
  text: string;
  playerRef: Player;
  setIsPlaying: Function;
  videoId: string;
}) => {
  const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const onRevealAnswer = useUpdatingCallback(() => {
    setIsAnswerRevealed(true);
  });

  const splitFn = type === 'multiple' ? __splitMultipleChoiceQuestion : __splitSingleQuestion;
  const { question, answer, questionChoicesWithoutTimeStamps } = splitFn(text);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: isMobile ? 14 : 24,
        paddingRight: isMobile ? 14 : 24,
        marginTop: 10,
        marginBottom: 6,
        flex: 1,
      }}
    >
      <div css={{ display: 'flex', flexFlow: 'row nowrap' }}>
        <div css={{ marginRight: 12 }}>{GPTSvg}</div>
        <div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <TextWithClickableTimestamp
            key={uuidv4()}
            text={question}
            playerRef={playerRef}
            setIsPlaying={setIsPlaying}
          />
          <div css={{ display: 'flex', flexDirection: 'column', marginTop: 12 }}>
            {questionChoicesWithoutTimeStamps.map((item, i) => (
              <TextWithClickableTimestamp
                key={uuidv4()}
                text={`${CHOICES[i]}. ${item}`}
                playerRef={playerRef}
                setIsPlaying={setIsPlaying}
              />
            ))}
          </div>
          <div>
            {isAnswerRevealed && (
              <div ref={messagesEndRef} css={{ marginTop: 12, marginBottom: 12 }}>
                <TextWithClickableTimestamp
                  key={uuidv4()}
                  text={answer}
                  playerRef={playerRef}
                  setIsPlaying={setIsPlaying}
                  extraCSS={{ color: 'lightGreen' }}
                />{' '}
              </div>
            )}
            <div
              css={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              {!isAnswerRevealed && (
                <BasicButton
                  onClick={onRevealAnswer}
                  text='Reveal Answer'
                  extraCSS={{ marginLeft: 'auto' }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <FeedbackButtons videoId={videoId} messageType={'quiz'} modelOutput={text} />
    </div>
  );
};

function __splitSingleQuestion(text: string): {
  question: string;
  answer: string;
  questionChoicesWithoutTimeStamps: string[];
} {
  const separatedTextWithSolution = text.replace('A.', 'A:').split('A:');

  const question = separatedTextWithSolution[0].replace('Q:', '').trimStart();
  const answer = separatedTextWithSolution[separatedTextWithSolution.length - 1]
    .replace('A:', '')
    .trimStart();

  return { question, answer, questionChoicesWithoutTimeStamps: [] };
}

function __splitMultipleChoiceQuestion(text: string): {
  question: string;
  answer: string;
  questionChoicesWithoutTimeStamps: string[];
} {
  let cleanText = text.replace(/\)/g, '.').replace(/:/g, '.');

  const separatedTextWithSolution = cleanText.split(/\s[a-zA-Z]\. /);
  if (separatedTextWithSolution[0] === '\n') {
    separatedTextWithSolution.shift();
  }

  const question = separatedTextWithSolution[0].replace('Q:', '').replace('A:', '').trimStart();
  const answer = separatedTextWithSolution[separatedTextWithSolution.length - 1];
  const questionChoicesWithTimeStamps = separatedTextWithSolution.splice(
    1,
    separatedTextWithSolution.length - 2,
  );
  const questionChoicesWithoutTimeStamps = __removeTimeStamps(questionChoicesWithTimeStamps);

  return { question, answer, questionChoicesWithoutTimeStamps };
}

function __removeTimeStamps(questionsChoices: string[]): string[] {
  const result: string[] = [];
  questionsChoices.forEach((str) => {
    const cleanedStr = str
      ?.replace(TIMESTAMP_REGEX, '')
      ?.replace('The correct answer is', '')
      ?.replace('Correct Answer:', '')
      ?.replace('Correct Answer.', '')
      ?.replace('- CORRECT ANSWER', '')
      ?.replace('Explanation:', '')
      ?.replace('Explanation.', '')
      ?.replace('Answer:', '')
      ?.replace('Answer.', '')
      ?.replace('A:', '')
      ?.replace('(', '')
      ?.replace(')', '');
    if (cleanedStr) {
      result.push(cleanedStr);
    }
  });
  return result;
}

export const TIMESTAMP_REGEX = /(\[|\()(\d*).(\d*).*(\d*).(\d*)(\]|\))/;
const CHOICES = 'ABCD';

export default React.memo(ChatQuiz);
